exportar tipo StreamResponseChunk<t> = {
    iteratorResult: IteratorResult<t>;
    siguiente: Promesa<streamresponsechunk<t>>;
};

/**
 * Transmite la respuesta de una función generadora asíncrona.
 * Esta función debe usarse para envolver una acción del servidor generador asíncrono.
 *
 * Ver https://github.com/vercel/next.js/discussions/51282
 */
exportar función streamResponse<t, P="" extends="" any[]="">(
    createGenerator: (...args: P) => AsyncGenerator<t>,
) {
    return async (...args: Parameters<typeof createGenerator="">) => {
        const generator = createGenerator(...args);
        return streamChunk<t>(generador);
    };
}

/**
 * Iterar sobre la respuesta de una función generadora asíncrona.
 */
export function iterateStreamResponse<t>(flujoRespuesta: Promesa<streamresponsechunk<t>>) {
    return {
        [Symbol.asyncIterator]: function () {
            return {
                current: streamResponse,
                async next() {
                    const { iteratorResult, next } = await this.current;

                    if (next) this.current = next;
                    else iteratorResult.done = true;

                    return iteratorResult;
                },
            };
        },
    };
}

async function streamChunk<t>(generador: AsyncGenerator<t>) {
    const next = generator.next();
    return new Promise<streamresponsechunk<t>>((resolve, reject) => {
        next.then((res) => {
            if (res.done) resolve({ iteratorResult: res });
            else resolve({ iteratorResult: res, next: streamChunk(generator) });
        });
        next.catch((error) => reject(error));
    });
}
</streamresponsechunk<t></t></t></streamresponsechunk<t></t></t></typeof></t></t,></streamresponsechunk<t></t></t>